.drawer {
  flex-shrink: 0;
  width: var(--drawer-desktop-width);
  box-shadow: var(--box-shadow-elevation-1);
  max-height: 100vh;
}

.drawerPaper {
  border: unset;
  width: var(--drawer-desktop-width);
}

.container {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
}

.logo {
  margin: 1rem 2rem 2rem 0;
  width: 100%;
  height: 6rem;
}

.laboratoryName {
  font-size: 1.5rem !important;
  font-weight: var(--font-weight-bold) !important;
  color: var(--primary) !important;
  line-height: normal;
}

.navStatParent {
  margin-top: 40%;
  padding-left: 6rem;
}

.navStateContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.8rem 1.8rem 1.8rem 0;
  color: var(--disabled);
  svg {
    margin-right: 1rem;
    font-size: 3.2rem;
  }
  img{
    max-height: 40px;
    max-width: 40px;
  }
  .navInfos {
    display: flex;
    flex-direction: column;
    p {
      font-size: 1.4rem;
      color: var(--disabled);
      font-weight: var(--font-weight-medium);
      word-wrap: break-word;
    }
  }
}

.navActiveInfo {
  color: var(--secondary);
  .navInfos {
    p{
      color: var(--text-primary)
    }
  }
}

.iconState {
  max-width: 3.2rem;
  margin-right: 1rem;
}

.patientName {
  word-break: keep-all !important;
}
