
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: var(--bg-light);
}

.innerContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  min-height: calc(100vh - var(--height-app-footer));
  @media screen and (max-width: 960px) {
    min-height: calc(100vh - var(--height-app-mobile-footer));
  }
}
