.parcoursCard {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    height: 230px;
    width: 400px;
    transition: transform .3s;
    box-shadow: 2px 3px 7px 4px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    margin: 2%;
    border-radius: 2rem;
    cursor: pointer;
    @media (max-width: 576px) {
        width: 100%;
        max-height: 150px;
    }
}

.parcoursCard:hover {
    transform: scale(1.04);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.parcoursCardContent {
    padding: 3rem 3rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 60%;
    justify-content: space-between;
    @media (max-width: 960px) {
        padding: 2rem 2rem;
    }
}

.parcoursCardTitle {

    width: 60%;
    margin-bottom: 1rem;
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 100%;
    text-align: left;
    color: #ffffff;
    @media (max-width: 960px) {
        font-size: 2.4rem;
    }
}

.parcoursCardTextArrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.parcoursCardTextArrow p {
    color: #fff;
    font-size: 1.6rem;
    text-align: left;
    line-height: 130%;
    max-width: 80%;
    @media (max-width: 960px) {
        font-size: 1.3rem;
    }
}

.parcoursCardArrowIcon {
    transform: scale(2);
    margin-left: auto;
}

.parcoursCardCheckIcon {
    transform: scale(2);
    margin-left: auto;
}