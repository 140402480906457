.buttonBase {
    padding: 1.5rem 4rem;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    border-radius: 5rem;
    cursor: pointer;
    display: inline-block;
    margin: 1.5rem 0;
    min-width: 15rem;
    width:  auto;  
    box-shadow: 2px 3px 7px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    place-content: center;
    @media (max-width: 960px) {
        margin: 0.8rem 0;
        padding: 1.2rem 3rem;
        min-width: 45%;
        width: -webkit-fill-available;
    }
    &:hover {
        box-shadow: none;
    }
}

.homeButtonPrimary {
    border: none;
    background: var(--btn-primary-gradient);
    color: white;
    transition: all 0.3s ease;
    &:hover {
        background: var(--btn-primary-gradient-transition)
    }
    @media (max-width: 960px) {
        width: auto;
        min-width: initial;
    }
}

.primary {
    border: none;
    background: var(--btn-primary-gradient);
    color: white;
    transition: all 0.3s ease;
    max-width: 350px;
    &:hover {
        background: var(--btn-primary-gradient-transition)
    }
}

.telechargerButton {
    max-width: none;
}

.secondary {
    border: 1px solid var(--primary);
    background: var(--white);
    color: var(--primary);
    transition: all 0.3s ease;
    &:hover {
        background: var(--primary); 
        color: var(--white);
    }
}

.buttonPrimaire {
    border: 2px solid var(--secondary);
    background-color: none;
    color: var(--secondary);
    &:hover {
        background-color: white;
        transition: all 0.3s ease;
    }
}

.buttonSecondaire {
    border: 2px solid white;
    background-color: var(--secondary);
    color: white;
    &:hover {
        transition: all 0.3s ease;
        border: 2px solid white;
        background-color: var(--dark-orange);
        color: white;
    }
}

.buttonSecondaireRight {
    border: 2px solid white;
    background-color: var(--secondary);
    color: white;
    margin-right: 0;
    &:hover {
        transition: all 0.3s ease;
        border: 2px solid white;
        background-color: var(--dark-orange);
        color: white;
    }
}

.buttonSecondaryModal {
    border: 2px solid white !important;
    background-color: var(--secondary) !important;
    color: white !important;
    min-width: 0 !important;
    &:hover {
        transition: all 0.3s ease !important;
        border: 2px solid white !important;
        background-color: var(--dark-orange) !important;
        color: white !important;
    }
}

.buttonIcon {
    font-size: 3rem;
}

.btn-icon-text {
    background: none;
    border: none;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    box-shadow: none;
    padding: 0;
    min-width: 0;
    justify-content: space-between;
}

.buttonBase:disabled,
.disabled {
    background: var(--grey-light);
    cursor: not-allowed;
    color: #fff;
    border: 1px solid #999;
}