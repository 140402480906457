.footer {
  height: var(--height-app-footer);
  padding: 1rem 8rem;
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  color: var(--grey-dark);
  line-height: 2rem;
  p {
    color: var(--grey-dark);
    font-size: 1.4rem;
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    height: var(--height-app-mobile-footer);
    padding: 2rem 1rem;
  }
}

.footerLinks{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
}

.linkContainer {
  a {
    margin-left: 2rem;
  }
}