
@for $i from 0 through 3 {
  .mt-#{$i} {
    margin-top: $i * 1rem;
  }

  .mb-#{$i} {
    margin-bottom: $i * 1rem;
  }

  .my-#{$i} {
    margin-bottom: $i * 1rem;
    margin-top: $i * 1rem;
  }

  .mx-#{$i} {
    margin-left: $i * 1rem;
    margin-right: $i * 1rem;
  }

  .m-#{$i} {
    margin: $i * 1rem !important;
  }
}