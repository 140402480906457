.header {
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  img {
    height: 6rem;
    @media screen and (max-width: 960px) {
      height: 4rem;
    }
  }
  h5 {
    color: var(--grey-light)
  }
}