.container {
  flex: 1;
  display: flex;
  flex-direction: column ;
  @media screen and (max-width: 960px) {
    padding: 0;
  }
}

.formContainer {
  flex:1;
  margin-top: 2rem;
}


.paperContent {
  display: flex;
  padding: 2rem;
  min-height: 95%;
  place-content: center;
}

.linearProgress {
  background-color: var(--grey-extra-light);
  height: 1.6rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 960px) {
  .lePassLogoContainer {
    display: none;
  }
}