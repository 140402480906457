.biologicalAssessmentCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    box-sizing: border-box;
    height: 230px;
    width: 400px;
    transition: transform .3s;
    box-shadow: 2px 3px 7px 4px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    margin: 2%;
    border-radius: 2rem;
    background-color: #ffffff;
    cursor: pointer;
    @media (max-width: 576px) {
        width: 100%;
        max-height: 150px;
    }
}

.biologicalAssessmentCard:hover {
    transform: scale(1.04);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.biologicalAssessmentCardTopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img {
        height: 140px;
        width: auto;
        @media screen {
            height: 90px;
            width: auto;
        }
    }
}

.biologicalAssessmentCardBottomContainer {
    display: flex;
    flex-direction: column;
    @media (max-width: 960px) {
        padding: 0 2rem 2rem 2rem;
    }
}

.biologicalAssessmentCardTitle {
    padding: 3rem 0 0 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--primary);
    @media (max-width: 960px) {
        font-size: 1.2rem;
        padding: 2rem 0 0 2rem;
    }
}

.biologicalAssessmentCardContent {
    padding: 2px 15px;
    @media (max-width: 960px) {
        padding: 2px 5px;
    }
}

.biologicalAssessmentCardBiologicalName {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: left;
    line-height: 130%;
    color: var(--black);
    @media (max-width: 960px) {
        font-size: 1.7rem;
    }
}

.biologicalAssessmentCard p {
    font-size: 2rem;
    font-weight: lighter;
    text-align: left;
    padding-top: 5px;
    line-height: 120%;
    @media (max-width: 960px) {
        font-size: 1.2rem;
    }
}

.biologicalAssessmentCardTextArrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 3rem 3rem 3rem;
    @media (max-width: 960px) {
        padding: 0 2rem 2rem 2rem;
    }
}

.biologicalAssessmentCardArrowIcon {
    transform: scale(2);
    margin-left: auto;
    color: var(--primary)
}

.biologicalAssessmentCardCheckIcon {
    transform: scale(2);
    margin-left: auto;
    color: var(--primary)
}