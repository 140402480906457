.smallWhiteCard {
    background-color: #ffffff;
    box-shadow: 2px 3px 7px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 300px;
    margin: 1rem 2rem;
    position: relative;
    @media screen and (max-width: 960px) {
        padding: 2rem;
        width: 100%;
        height: fit-content;
        margin: 1rem 0;
    }
}

.smallWhiteCard .title {
    font-size: 1.6rem;
    font-weight: bolder;
    color: var(--grey-dark);
    text-align: left;
    color: var(--primary);
    margin-bottom: 1rem;
    @media screen and (max-width: 960px) {
        font-size: 1.4rem;
    }
}

.smallWhiteCard .topRow {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem 3rem 0 3rem;
    text-align: left;
    min-width: 100%;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 960px) {
        padding: 0;
        width: 100%;
    }
}

.smallWhiteCard .bottomRow {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    z-index: 1;
}

.smallWhiteCard p {
    font-size: 1.8rem;
    right: 0;
    @media screen and (max-width: 960px) {
        font-size: 1.6rem;
    }
}

.smallWhiteCard .pictoCard {
    max-height: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
    @media screen and (max-width: 960px) {
        display: none;
    }
}