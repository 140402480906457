.largeWhiteCard {
    background-color: #ffffff;
    box-shadow: 2px 3px 7px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    // justify-content: center; // l'alignement est centré par défaut dans le composant avec la possibilité de le modifier en props
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    @media screen and (max-width: 960px) {
        padding: 1rem;
        width: 100%;
    }
}

.largeWhiteCard .title {
    font-size: 1.8rem;
    font-weight: bolder;
    color: var(--grey-dark);
    text-align: left;
    @media screen and (max-width: 960px) {
        font-size: 1.4rem;
    }
}

.largeWhiteCard .leftColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3rem;
    text-align: left;
    @media screen and (max-width: 960px) {
        padding: 0;
        width: 100%;
    }
}

.largeWhiteCard .rightColumn {
    display: flex;
    align-self: flex-end;
}

.largeWhiteCard p {
    font-size: 2rem;
    @media screen and (max-width: 960px) {
        font-size: 1.6rem;
    }
}

.largeWhiteCard .pictoCard {
    display: block;
    max-width: 260px;
    position: absolute;
    right: 0;
    bottom: 0;
    @media screen and (max-width: 1430px) {
        display: none;
    }
}
