.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.formContent {
  margin-top: 2rem;
  @media screen and (max-width: 960px) {
    margin-top: 1rem;
  }
}

.formContainer {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
  text-align: center;
}

.formContainerColumn {
  flex: 1;
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem;
  @media screen and (max-width: 960px) {
    padding: 1rem 0
  }
}

.halfWidth {
  width: 50%;
  text-align: center;
  @media (max-width: 960px){
    width: 100%;
  }
}

.formHeader {
  display: grid;
  width: 100%;
  grid-template-columns: 150px 1fr 150px;
  grid-template-areas: "col1 col2 col3";
  align-items: center;
  text-align: center;
  place-self: center;
  gap: 30px;
  
  h3 {
    color: var(--text-secondary)
  }

  .back {
    grid-area: col1;
    justify-self: center;
  }
  
  .title {
    grid-area: col2;
  }
  
  .next {
    grid-area: col3;
    justify-self: center;
  }

  @media (max-width: 960px) {
    gap: 10px;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto;
    grid-template-areas: "col1 col2 col3";
  }
}

.formHeader .back, .formHeader .next {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  color: var(--secondary);
  font-size: 4rem;
  font-weight: var(--font-weight-medium);
  svg {
    font-size: inherit;
  }
  @media screen   and (max-width: 960px) {
    font-size: 3rem;
  }
}

.formHeader .title {
  text-align: center;
  margin:2rem 0;
  @media (max-width: 960px) {
    margin:1rem 0;
  }
}

//LaboratoryForm

.LaboratoryItemContainer {
  border-radius: var(--border-radius-shape);
  background: var(--btn-primary-gradient);
  padding: 1rem 2rem;
  margin: 1rem 0;
  height: 8rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    color: var(--white);
    font-size: 2.4rem;
  }
  &:hover {
    transform: translateX(2rem);
    background: var(--btn-primary-gradient-transition);
    .LaboratoryItemHover{
      opacity: 1;
    }
    &::before {
      width: 100%
    }
    svg {
      color: var(--primary)
    }
    @media (max-width: 960px) {
      transform: none;
    }
  }
}

.formContainerColumnCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 80%;
}

.selected {
  background: var(--btn-primary-gradient);
}


.LaboratoryItemAddress {
  text-align: left;
  color: var(--white);
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
  p {
    color: var(--white);
    font-weight: var(--font-weight-regular);
  }
}

.labSelectContainer {
  position: relative;
  display: flex;
  flex-basis: row nowrap;
  background-color: #FFF;
  border-radius: 2rem;
  align-items: center;
  img {
    height: 10rem;
    position: absolute;
    z-index: 10;
  }
  .reactSelectContainer {
    flex: 1;
  }
}

// Identity

.identityGrid {
  padding: 2%;
}

// Context

.contextQuestionGroup {
  margin-bottom: 3rem;
}

.questionLabel {
  font-size: 1.8rem;
  color: var(--text-primary);
  font-weight: 500;
}

.formCheckLabel {
  font-size: 1.8rem;
  color: var(--text-primary);
  line-height: 2.3rem;
  margin-left: 0.5rem;
}

.formRadioGroup {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.formCheckInput {
  margin-right: 0.5rem;
  margin-top: 1.5rem;
}

.formTextInput {
  width: 100%;
  padding: 1rem;
  font-size: 1.7rem;
  border-radius: 8px;
  border: 1px solid grey;
  outline: none;
  box-sizing: border-box;
  margin-top: 1rem;
}

.requiredNotice {
  font-size: 1.7rem;
  color: var(--primary);
  margin-top: 1rem;
}

//Confirmation

.labSummary {
  display: flex;
  text-align: center;
  border: 2px solid var(--primary);
  width: fit-content;
  padding: 2%;
  flex-direction: column;
  margin: auto;
}

.labSummaryName {
  color: var(--primary);
  margin-bottom: 1rem;
}

// Upload

.validTitle {
  color: var(--success);
}

.invalidTitle {
  color: var(--error);
  margin-bottom: 2%;
}

.imageContainer {
  // display: flex; 
  display: inline-block;
  flex: 1; 
  flex-direction: column; 
  align-items: center; 
  position: relative;
  @media (max-width: 960px) {
    height: fit-content;
    width: 100%;
  }
}

.imagePreview {
  background-color: #FFF;
  max-width: 90%;
  max-height: 57%;
  border-radius: 2rem;
}

.pdfPreview {
  background-color: #FFF;
  width: 100%;
  height: 500px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin-top: 2rem;
  @media screen and (max-width: 960px) {
    height: 50vh;
    margin-top: 1rem;
  }
}

.iconActive {
  color: var(--secondary);
}

.redBulletList {
  list-style: none;
  text-align: left;

  li::before {
    content: "\2022";
    color: var(--primary);
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    transform: scale(2);
  }

  li {
    font-size: 1.8rem;
    margin: 1rem;
    
  @media (max-width: 960px) {
    font-size: 1.4rem;
  }
  }

  @media (max-width: 960px) {
    padding: 1rem;
  }
}

.alertContainer {
  width: 100%;
  display: flex;
  place-content: center;
}

.alertMessage {
  font-size: 1.6rem;
  color: var(--primary);
  text-align: center;
  margin-top: 2rem;
}

.pContent {
  margin: 0;
  color: var(--text-primary);
  font-size: 1.8rem;
  line-height: 2.8rem;
  @media (max-width: 960px) {
    margin-top: 0.6rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

.pContentForm {
  margin-bottom: 2rem;
  color: var(--text-primary);
  font-size: 1.6rem;
  @media (max-width: 960px) {
    font-size: 1.4rem;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  place-content: center;
  margin: 0.5%;
  border-radius: var(--border-radius-shape);
  background-color: #f2f2f2;
  padding: 20px;
  box-sizing: border-box;
  max-height: 300px;
  min-width: 200px;
}

.cellTitle {
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin-bottom: 10px;
}

.cellIconImg{
  height: 30px;
  margin-right: 10px;
}

.cellIcon {
  color: var(--secondary);
  transform: scale(2.1);
  margin-right: 10px;
}

.cellContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.flexContainer {
  margin: 2% 0;
  display: flex;
  flex-wrap: wrap;
  place-content: center;
}

.flexItem {
  flex: 1;
  max-height: 300px;
  overflow: hidden;
  padding: 1%;
  box-sizing: border-box;
  transition: filter 0.3s ease;
}

.h3Success {
  font-size: 2.2rem;
  color: var(--success);
  @media (max-width: 960px){
    font-size: 1.8rem;
  }
}

.cardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  place-content: center;
  @media (max-width: 960px) {
      max-width: calc(100vw - 40px);
  }
}

.radioLabel {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  text-align: left !important;
}

.radioOptionLabel {
  font-size: 1.7rem;
  color: var(--text-primary);
  padding-bottom: 8px;
}

.radioFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap;

  .radioLabel {
    margin-right: auto;
    color: var(--text-primary);
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;

    .radioLabel {
      margin-right: 0;
    }
  }
}

.prescriptionForm {
  display: flex;
  flex-flow: wrap;
}

.prescriptionFormGroup {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2%;
}

.labelCol {
  box-sizing: border-box;
}

.inputCol {
  box-sizing: border-box;
}

.firstFormGroup {
  flex-grow: 0;
  flex-shrink: 1;
  width: auto;
  padding-right: 2rem;
  @media (max-width: 1400px) {
    flex-flow: wrap;
    padding-right: 0;
    width: 100%;
  }
}

.secondFormGroup {
  flex-grow: 1;
  flex-shrink: 1;
  width: auto;
  padding-right: 1rem;
  @media (max-width: 1400px) {
    flex-flow: wrap;
    padding-right: 0;
  }
}

@media (max-width: 1399px) {
  .labelCol, .inputCol {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .inputCol {
    padding-left: 0;
  }
}

.selectPrescriptor, .dateFieldClass {
  width: 100%;
  padding: 16.5px 14px;
}

.labelPrescription {
  color: var(--text-secondary);
  font-size: 1.7rem;
  margin-bottom: 0;
  width: 20%;

  @media (max-width: 960px) {
    font-size: 1.4rem;
  }

  @media (max-width: 600px) {
    margin-bottom: 10px;
    width: 100%;
  }
}

.autocompleteContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.infoIcon {
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%) scale(2);
  color: var(--grey-dark);
  @media (max-width: 1399px) {
    position: absolute;
    right: 10px;
    top: -13px;
    transform: translateY(-50%) scale(1.5);
    color: var(--secondary);
  }
}

.infoIconWarning {
  color: var(--secondary);
}

.infoIconDanger {
  color: var(--primary);
}

.customTooltip {
  font-size: 1.4em !important;
  text-align: center !important;
  padding: 10px !important;
}

.customSelectInput {
  font-size: 1.5rem !important;
  @media (max-width: 960px) {
    font-size: 1.3rem !important;
    padding-right: 0;;
  }
}

.disabledField {
  background: rgb(178 178 178 / 13%);
}

.noMargin {
  margin: 0px !important;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  margin: 0 -4rem -3rem -4rem;
  padding: 0 4rem;
  border-top: 2px solid var(--border-grey-light);
  @media (max-width: 960px) {
    margin: 0 -2rem;
    padding: 0 2rem;
  }
}

.buttonsContainer button {
  margin: 1rem 0 !important;
  min-width: 20rem;
}

.gridContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1% 5%;

  .cellOneThird, .cellTwoThirds {
    padding: 2%;
  }

  .cellOneThird {
    flex: 0 0 30%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cellTwoThirds {
    flex: 0 0 70%;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cellHalf {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem;
    @media screen and (max-width: 960px){
      padding: 0;
    }
  }

  .cellHalf .imagePreview {
    max-height: 100%;
    margin: 2rem 0;
  }
  
  .thirdWidthColumn {
    flex: 0 0 30%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem;
  }
  
  @media screen and (max-width: 960px) {
    .thirdWidthColumn {
      padding: 1rem 0;
    }
  }
  
  .thirdWidthColumn .imagePreview {
    max-height: 100%;
  }
  
  .twoThirdWidthColumn {
    flex: 0 0 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem;
  }
  
  @media screen and (max-width: 960px) {
    .twoThirdWidthColumn {
      padding: 1rem 0;
    }
  }
  
  .twoThirdWidthColumn .imagePreview {
    max-height: 100%;
  }

  .pContentGrid {
    text-align: left;
  }

  @media (max-width: 768px) {
    .cellOneThird, .cellTwoThirds, .cellHalf, .thirdWidthColumn, .twoThirdWidthColumn{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .pContentGrid {
      text-align: center;
    }
  }
  .iconAgrandie {
    width: 250px;
    max-width: 80%;
    height: auto;
    align-self: center;
    @media (max-width: 768px) {
      width: 100px;
      max-width: 80%;
      height: auto;
    }
  }
}

.nonConformityMessage {
  padding: 1rem;
  text-align: left;
  width: 100%;
  p {
    font-size: 1.8rem !important;
    color: var(--grey-light);
    @media (max-width: 960px) {
      font-size: 1.4rem !important;
    }
  }
}


.nonConformityMessage ul {
  font-size: 1.8rem;
  text-align: left;
  font-weight: bold;
  list-style-type: none;
  padding: 0 !important;
  margin-left: 0; 
  line-height: 150%;
  @media (max-width: 960px) {
    font-size: 1.4rem;
    text-align: left;
    padding: 1vw;
  }
}

.nonConformityMessage button {
  margin-left: 0px
}


.infoLue {
  color: var(--success);
}

.infoNonLue {
  color: var(--error);
}

.custom_popup_class {
  width: 400px !important;
}

.errorMessage {
  color: #f44336;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-bottom: 1.2rem;
}

// NPS FORM

.npsContainer {
  display: flex;
  flex-direction: column;
}

.npsComment {
  background-color: var(--bg-light);
  & > div:first-child textarea {
    border-radius: 0;
    background-color: var(--bg-light);
    top:0;
  }
  & > div:first-child fieldset {
    top: 0;
  }
}

.buttonRed {
  color: var(--error);
  border-color: var(--error) !important;
  color: white !important;
}

.redText {
  color: var(--error);
}

.italicText {
  font-style: italic;
}

.buttonOrange {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: white !important;
}

.buttonYellow {
  background-color: #F3D63B !important;
  border-color: #F3D63B !important;
  color: white !important;
}

.buttonLightGreen {
  background-color: #7FC600 !important;
  border-color: #7FC600 !important;
  color: white !important;
}

.buttonGreen {
  background-color: #05AE24 !important;
  border-color: #05AE24 !important;
  color: white !important;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  flex-wrap: wrap;
  @media screen and (max-width: 960px) {
    width: 100%;
    justify-content: space-between;
  }
}


.addDocumentsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  @media screen and (max-width: 960px) {
    width: 100%;
    justify-content: space-between;
  }
}

.documentsCategory {
  width: 50%;
  @media screen and (max-width: 1580px) {
    width: 100%;
  }
}

.largeButtonsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  justify-content: flex-start;
  margin-top: 2rem;
  @media screen and (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}
.noMarginLeftButton {
  margin-left: 0 !important;
}

.greyNotice {
  color: var(--grey-light);
  font-weight: bold;
  font-size: 1.6rem;
  @media screen and (max-width: 960px) {
    font-size: 1.4rem;
    
  }
}

.regularNotice {
  font-size: 1.6rem;
  @media screen and (max-width: 960px) {
    font-size: 1.4rem;
    
  }
}

.npsNumber {
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  padding: 0 !important;
  min-width: 0 !important;
  font-size: 1.5rem !important;
  margin: 5px !important;
  border-color: grey !important;
  color: rgb(0, 0, 0) !important;
  @media (max-width: 960px) {
    width: 40px;
    height: 40px;
  }
}

.npsLabel {
  font-size: 1.8rem !important;
  margin: 0;
}

.probabilityText {
  font-size: 1.6rem !important;
}

.responsiveButtonGroup {
  place-content: center;
  display: flex;
  flex-wrap: wrap;

  .MuiButton-root {
    flex: 1 1 auto;
    margin: 4px;
  }
}

.recapDocuments {
  display: flex;
  flex-direction: column;
  p {
    margin: 0 0 1rem 0;
  }
  a {
    text-decoration: underline;
  }
  @media screen and (max-width: 960px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;
    p {
      margin: 0 4rem 0 0;
    }
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkboxContainer input {
  margin-right: 10px;
}

.selectField {
  margin-bottom: 2rem;
  padding: 0.5rem;
  font-size: 1.6rem;
}

.customSelect {
  margin-top: 1rem;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 1.8rem;
  @media (max-width: 960px) {
    font-size: 1.6rem;
  }
}
