// LayoutBackground.module.scss

.layoutBackground {
  // min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  @media (max-width: 960px) {
    background-position: center;
  }
}
