:root {
  /* COLORS */
  --primary: #E53012;
  --secondary: #FF671F;
  --dark-orange: #e75b1a;
  --success: #28b73e;
  --error: #f44336;
  --orange: #FF671F;
  --red: #E53012;
  --yellow: #F7A703;
  --black: #000;
  --white: #fff;
  --disabled: #C4C4C4;
  --grey-dark: #505050;
  --grey-light: #818181;
  --grey-very-light: #c8c8c8;
  --grey-extra-light: #f2f2f2;
  --border-grey-light: #e8e8e8;
  --bg-light: #f5f5f5;
  --text-primary: #000;
  --text-secondary: #505050;
  --text-hint: rgba(0, 0, 0, 0.38);
  --text-footer: #8d8d8d;
  --text-overline: #c1c1c1;
  --link: #005282;
  --map: #fbe4c6;
  --solitaire: #fef5e9;
  --box-shadow-elevation-1: 0 5px 15px rgba(0, 0, 0, 0.1);

  /* GRADIANTS */
  --btn-primary-gradient: linear-gradient(30deg, var(--orange) 0%, var(--red) 40%);
  --btn-primary-gradient-transition: linear-gradient(30deg, var(--yellow) 0%, var(--orange) 100%);

  /* FONT-WEIGHTS */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  /* WIDTHS */
  --drawer-mobile-width-max: 425px;
  --drawer-desktop-width: 350px;
  --catalog-desktop-width-max: 1150px;
  --max-width-news-carousel-card: 380px;

  /* HEIGHTS */
  --height-app-bar-mobile: 60px;
  --height-app-bar-desktop: 102px;
  --height-header-hero-desktop: 260px;
  --height-article-card-media-dense: 150px;
  --height-news-carousel-card-media-desktop: 215px;
  --height-news-carousel-card-media-mobile: 150px;
  --height-app-footer: 5rem;
  --height-app-mobile-footer: 8rem;

  /* SIZES */
  --size-icon-drawer-entry: 32px;

  /* MARGINS */
  --margin-left-page-content: 7%;
  --margin-right-page-content: 7%;
  --margin-left-icon-drawer-entry: 25px;
  --margin-right-icon-drawer-entry: 12px;

  /* BREAKPOINTS */
  --breakpoint-extra-small: 0;
  --breakpoint-very-small: 400px;
  --breakpoint-small: 600px;
  --breakpoint-small-medium: 760px;
  --breakpoint-medium: 960px;
  --breakpoint-medium-large: 1200px;
  --breakpoint-large: 1440px;
  --breakpoint-extra-large: 1920px;

  /* Z-INDEXES */
  --z-index-mobile-stepper: 1000;
  --z-index-speed-dial: 1050;
  --z-index-app-bar: 1100;
  --z-index-drawer: 1200;
  --z-index-modal: 1300;
  --z-index-snackbar: 1400;
  --z-index-tooltip: 1500;
  --z-index-cookies-dialog: 2000;

  /* BORDER_RADII */
  --border-radius-shape: 15px;

  /* DURATIONS */
  --duration-shortest: 150ms;
  --duration-shorter: 200ms;
  --duration-short: 250ms;
  --duration-standard: 300ms;
  --duration-complex: 375ms;
  --duration-entering-screen: 225ms;
  --duration-leaving-screen: 195ms;
  --duration-long: 1000ms;
}