.alert {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  font-size: 1.5rem;
  border-radius: 15px;
  min-width: fit-content;
  svg {
    margin-right: 1rem;
    font-size: 2rem;
  }
  @media screen and (max-width: 960px) {
    min-width: none;
  }
}

.alert-error {
  background-color: var(--primary);
  color: var(--white);
  margin: 0 0 2rem 0;
  width: 100%;
  font-size: 1.7rem;
  svg {
    color: var(--white);
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    text-align: left;
    font-size: 1.4rem;
  }
}

.alert-success {
  background-color: var(--success);
  color: var(--white);
  margin: 0 0 2rem 0;
  width: 100%;
  font-size: 1.7rem;
  svg {
    color: var(--white);
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    text-align: left;
  }
}

.alert-warning {
  background-color: var(--secondary);
  color: var(--white);
  margin: 0 0 2rem 0;
  width: 100%;
  font-size: 1.7rem;
  svg {
    color: var(--white);
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    text-align: left;
  }
}