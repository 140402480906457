
* {
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
}
// 1 rem = 10px

html,
body {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  margin: 0;
  padding: 0;
  color: var(--text-primary);
  background-color: var(--bg-light);
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 5.6rem;
  margin: 0;
  @media (max-width: 960px){
    font-size: 3.2rem;
  }
}

h1 > strong {
  font-weight: var(--font-weight-medium);
}

h2 {
  color: var(--primary);
  font-size: 1.6rem;
  margin: 0;
  font-weight: 800;
  text-transform: uppercase;
  @media (max-width: 960px){
    font-size: 1rem;
  }
}

h3 {
  font-size: 3.4rem;
  margin: 0;
  color: var(--grey-dark);
  @media (max-width: 960px){
    font-size: 2.2rem;
  }
}

h4 {
  font-size: 2.4rem;
  font-weight: lighter;
  margin: 0;
  text-align: center;
  @media (max-width: 960px){
    font-size: 2rem;
  }
}

h5 {
  font-size: 2rem;
  margin: 0;
  @media (max-width: 960px){
    font-size: 1.4rem;
  }
}

strong {
  font-weight: var(--font-weight-bold);
}

p {
  margin: 0;
  color: var(--text-primary);
  font-size: 1.8rem;
  line-height: 150%;
  @media (max-width: 960px){
    font-size: 1.4rem;
  }
}