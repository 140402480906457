.appContainer {
    min-height: calc(100vh - 7rem - 5rem);
    width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 960px) {
        flex-direction: column;
        padding-top: 8vh;
        min-height: calc(100vh - 7rem - 8rem);
    }
}

.content{
    z-index: 1;
    width:50%;
    display: flex;
    flex-direction: column;
    align-self: center;
    @media screen and (max-width: 960px) {
        width: 100%;
    }
}

.picto{
    width:50%;
    @media screen and (max-width: 960px) {
        display: none;
    }
}

.maintenanceMode {
    color: var(--primary);
    text-align: center;
    margin-top: 2rem;
    @media (max-width: 959px) {
        margin-top: 6rem;
    }
}

.textHome {
    font-size: 2.4rem;
    font-weight: lighter;
    margin: 2rem 0;
    text-align: left;
    width: 60%;
    @media screen and (max-width: 960px) {
        width: 100%;
        font-size: 2rem;
        text-align: left;
        margin: 1rem 0;
    }
}

.topRow {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem 3rem 0 3rem;
    text-align: left;
    min-width: 100%;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 960px) {
        padding: 0;
        width: 100%;
    }
}

.bottomRow {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    z-index: 1;
}

.pictoCard {
    position: absolute;
    bottom: 10vh;
    right: 10vw;
    max-height: calc(100vh - 70px);
    @media screen and (max-width: 960px) {
        display: none;
    }
}

.footerHome {
    height: var(--height-app-footer);
    padding: 1rem 0;
    display: flex;
    flex-direction: 'row';
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    color: var(--grey-dark);
    line-height: 2rem;
    p {
        color: var(--grey-dark);
        font-size: 1.4rem;
    }
    @media screen and (max-width: 960px) {
        flex-direction: column;
        height: var(--height-app-mobile-footer);
        padding: 2rem 1rem;
    }
}