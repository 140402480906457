@use "./variables";
@use "./utils";
@use "./base";

.content-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  min-height: 0;
  @media (max-width: 960px) {
    margin-top: var(--height-app-bar-mobile);
  }
}

.single-btn-container {
  display: flex;
  &.btn-right {
    justify-content: end;
  }
  &.btn-center {
    justify-content: center;
  }
  @media (max-width: 960px) {
    &.btn-right {
      justify-content: center;
      button {
        width: 80%
      }
    }
  }
}

.alignCenter {
  text-align: center;
}

.text-highlight {
  color: var(--secondary)
}

.text-alert {
  color: var(--primary)
}


@for $i from 0 through 10 {
  .nps-#{$i} {
    @if $i <= 2 {
      &:hover {
        background-color: rgba(244, 67, 54, 0.2)!important;
      }
    } @else if $i <= 4 {
      &:hover {
        background-color: rgba(240, 150, 0, 0.2)!important;
      }
    } @else if $i <= 6 {
      &:hover {
        background-color: rgba(243, 214, 59, 0.2)!important;
      }
    } @else if $i <= 8 {
      &:hover {
        background-color: rgba(127, 198, 0, 0.2)!important;
      }
    } @else {
      &:hover {
        background-color: rgba(5, 174, 36, 0.2)!important;
      }
    }
  }
}

.bgWhite {
  background-color: var(--white);
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.zoom-in {
  animation: zoomIn 0.3s ease forwards;
}

.zoom-out {
  animation: zoomOut 0.3s ease forwards;
}
