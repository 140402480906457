.customModal {
    position: relative;
    
    .closeButton {
        position: absolute;
        top: 0;
        right: 0;
        color: #aaa;
        width: 40px;
        background: none;
        height: auto;
        padding: 0;

        &:hover {
            color: #888;
            background: none;
        }
    }

    .closeIcon {
        width: 30px;
        height: auto;
    }


    .MuiBox-root {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    
    .modalTitle {
        margin-bottom: 20px;
        text-align: left;
        font-size: 2.4rem;
        color: var(--grey-dark);
        @media (max-width: 960px){
            font-size: 2rem;
        }
    }
    
    label {
        font-size: 1.5rem;
        color: #505050;
        font-weight: 600;
        line-height: 1.4375em;
    }

    input {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 1.5rem;
    }

}
