.NavIconsMobileContainer {
    display: none;

    @media (max-width: 960px) {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 2rem;
    }
}

.gridItem {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
