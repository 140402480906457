.header {
  display: flex;
  justify-content: space-between;
  height: 7rem;
  align-items: center;
  justify-content: right;
  h5 {
    color: var(--grey-light);
  }
  @media screen and (max-width: 960px) {
    height: 4rem;
  }
}