
.flexColumn {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-height: 300px;
    min-width: 200px;
}

.cellTitle {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    margin-bottom: 10px;
}

.cellIconImg{
    height: 30px !important;
    margin-right: 10px !important;
}

.cellContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.imagePreviewContainer {
    display: flex;
    flex: 1; 
    flex-direction: column; 
    align-items: center; 
    height: 90vh;
    position: relative;
    margin: 10px;
    max-height: 80%;
    place-content: center;
    @media (max-width: 960px) {
        height: fit-content;
    }
}


.imgPreview {
    border: 1px solid var(--grey-light);
    max-width: 90%;
    max-height: 57%;
    margin: 3% 0;
}

.zoomIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem !important;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
}

@media (min-width: 961px) {
    .imgPreview:hover {
        filter: brightness(0.8);
    }
}

@media (max-width: 960px) {
    .flexColumn {
        flex-basis: 100%;
        max-height: none;
    }
}

.flexColumn img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-height: 100%;
}