// CSS pour les Boutons CARDS

.card {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    height: 180px;
    transition: transform .3s;
    box-shadow: 0 5px 15px rgba(0,0,0,.1);
    border-radius: 15px;
    background-color: #fff;
    color: #000;
    margin-bottom: 40px;
    margin: 1vw;
    width: calc(50% - 40px);
    @media (max-width: 960px) {
        width: calc(100% - 40px);
        max-height: 150px;
    }
}

.card:hover {
    transform: scale(1.04);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card img {
    border-radius: 5px 0 0 5px;
    min-width: 60%;
    object-fit: cover;
}

.cardBody {
    padding: 2px 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 40%;
}

.card a {
    font-size: 2rem;
    font-weight: 600;
}

.card p {
    font-size: 1.7rem;
    font-weight: 500;
    text-align: left;
    padding-top: 20px;
    @media (max-width: 960px) {
        font-size: 1.3rem;
    }
}
