.documentCard {
  background: #F5F5F5;
  box-shadow: 2px 3px 7px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  width: 100%;
  margin-bottom: 2rem;
  @media screen and (max-width: 960px) {
    padding: 1rem;
    width: 100%;
  }
}

.statusIndicator {
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 1rem;
}

.fileName {
  flex-grow: 1;
}

.deleteIcon {
  cursor: pointer;
  font-size: 2.5rem !important;
}
